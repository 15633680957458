<template>
  <div class="telia-grid-wrapper" :class="[{ 'wide-table': isWideTable }]">
    <telia-grid>
      <telia-row>
        <telia-col width="12">
          <section :aria-label="t('connectedVpns.heading')">
            <div v-if="error">
              <telia-notification status="error" heading-tag="h3">
                <span slot="heading">
                  <telia-visually-hidden>{{ t("common.viewError.content") }}</telia-visually-hidden>
                  <span>{{ t("common.viewError.title") }}</span></span
                >
                <telia-p slot="content">{{ t("common.viewError.content") }}</telia-p>
              </telia-notification>
            </div>

            <b2x-controlled-table
              v-else
              ref="vpntable"
              :columns="JSON.stringify(columns)"
              :data="JSON.stringify(rowsForPage)"
              :is-wide-table="isWideTable"
              show-filter-row
              show-clear-filters-button
              show-expand-button
              :table-rows-count="rows.length"
              :table-rows-limit="rowsForPage.length"
              @filterColumnInput="onFilterColumnInput"
              @clearAllFilters="onClearAllFilters"
              @columnClick="onColumnClick"
              @rowClicked="handleRowClick"
            ></b2x-controlled-table>

            <b2x-paginator
              :list-length="rows.length"
              :page-sizes="JSON.stringify(pageSizes)"
              :default-page-size="defaultPageSize"
              @paginationChange="handlePageChange"
            />

            <b2x-table-skeleton v-if="loading" rows="3" show-header="false"></b2x-table-skeleton>

            <b2x-drawer
              drawer-id="edit-vpn-alias-drawer"
              position="right"
              :heading="t('connectedVpns.alias.heading', { 0: vpnId })"
              :is-open="isDrawerOpen"
              @drawerClose="closeModal()"
            >
              <update-alias
                :alias="selectedAlias"
                :vpnId="vpnId"
                :status="status"
                :saving="savingAliasState"
                @saveAlias="saveAlias"
                @close-modal="closeModal()"
              ></update-alias>
            </b2x-drawer>
          </section>
        </telia-col>
      </telia-row>
    </telia-grid>
  </div>
</template>

<script>
import { translateSetup, translateMixin } from "../locale";
import { getScopeIdOrThrow } from "@telia/b2b-customer-scope";
import { logError } from "@telia/b2x-logging";
import { hasPermission } from "@telia/b2b-logged-in-service";
import UpdateAlias from "./update-alias.vue";
import "@telia/b2x-drawer";

// magic required by b2x-table, not sure what this does
import {
  applyPolyfills as b2xTableApplyPolyfills,
  defineCustomElements as b2xTableDefineCustomElements,
} from "@telia/b2x-table/dist/loader";

import {
  createTableColumn,
  getColumnTitle,
  getVpns,
  saveVpnAlias,
  searchRows,
  sortRows,
} from "@telia/b2b-vpn-table";

b2xTableApplyPolyfills().then(() => b2xTableDefineCustomElements(window));

const statuses = {
  SUCCESS: {
    type: "success",
    heading: "connectedVpns.alias.successMessage.title",
    content: "connectedVpns.alias.successMessage.content",
  },
  ERROR: {
    type: "error",
    heading: "connectedVpns.alias.errorMessage.title",
    content: "connectedVpns.alias.errorMessage.content",
  },
  INFORMATION: {
    type: "information",
    heading: "connectedVpns.alias.informationMessage.title",
    content: "connectedVpns.alias.informationMessage.content",
  },
};

export default {
  name: "VpnCard",
  mixins: [translateMixin],
  components: {
    UpdateAlias,
  },
  data: () => {
    return {
      error: false,
      loading: true,
      savingAliasState: false,
      tableData: [],
      isDrawerOpen: false,
      isWideTable: false,
      selectedAlias: "",
      selectedValue: "",
      scopeId: undefined,
      vpnId: undefined,
      status: undefined,
      pageSizes: [10, 25, 50],
      page: 1,
      pageSize: 10,
      defaultPageSize: 10,
      columnFilter: {},
      sorting: {
        id: "ORIGIN",
        name: "ORIGIN",
        alias: "ORIGIN",
        organisationName: "ORIGIN",
        encryption: "ORIGIN",
        multicast: "ORIGIN",
      },
      selectedSorting: "",
    };
  },
  async mounted() {
    addEventListener("controlledTableExpandClicked", this.toggleWideTable);
    this.scopeId = await getScopeIdOrThrow();
    await this.fetchData();
  },
  async created() {
    await translateSetup();
    this.hasWritePermission = await hasPermission("WRITE_DATACOM_VPN_ALIAS");
  },
  destroyed() {
    removeEventListener("controlledTableExpandClicked", this.toggleWideTable);
  },
  methods: {
    async fetchData() {
      try {
        this.tableData = await getVpns(this.scopeId);
        this.filteredRows = this.tableData;
      } catch (error) {
        logError("b2b-vpn-administration", "Error populating VPN table data");
        this.error = true;
      }
      this.loading = false;
    },
    onClearAllFilters() {
      this.columnFilter = {};
    },
    sortRows(filteredRows) {
      return sortRows(filteredRows, this.selectedSorting, this.sorting);
    },
    searchRows() {
      return searchRows(this.tableData, this.columnFilter);
    },
    onColumnClick(event) {
      const titleKey = getColumnTitle(event.detail);
      this.selectedSorting = titleKey;
      if (this.sorting[titleKey] === "ORIGIN") {
        this.sorting[titleKey] = "ASC";
      } else if (this.sorting[titleKey] === "ASC") {
        this.sorting[titleKey] = "DSC";
      } else if (this.sorting[titleKey] === "DSC") {
        this.sorting[titleKey] = "ORIGIN";
      }
    },
    onFilterColumnInput(event) {
      const { column, value } = event.detail;
      const titleKey = getColumnTitle(column);

      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        if (value?.length > 0) {
          this.columnFilter = {
            ...this.columnFilter,
            [titleKey]: value,
          };
        } else {
          const columnFilter = { ...this.columnFilter };
          delete columnFilter[titleKey];
          this.columnFilter = columnFilter;
        }
      });
    },
    handlePageChange(event) {
      const { page, pageSize } = event.detail;
      this.page = page;
      this.pageSize = pageSize;
    },
    closeModal() {
      this.isDrawerOpen = false;
      this.status = undefined;
    },
    handleRowClick(event) {
      if (this.hasWritePermission) {
        this.selectedAlias = this.rowsForPage[event.detail][2];
        this.selectedValue = this.rowsForPage[event.detail][6];
        this.vpnId = this.rowsForPage[event.detail][0];
        this.isDrawerOpen = true;
      }
    },
    async saveAlias(val) {
      this.status = undefined;
      this.savingAliasState = true;
      try {
        await saveVpnAlias({ customerAlias: val }, this.scopeId, this.selectedValue);
        await this.fetchData();
        this.status = statuses.SUCCESS;
        this.savingAliasState = false;
      } catch (error) {
        if (error?.body?.translationKey === "PARTIAL_ERROR_UPDATING_VPN_ALIAS") {
          this.status = statuses.INFORMATION;
          logError(
            "b2b-vpn-administration",
            "Error saving VPN alias immediatelly, will be 24h delay"
          );
          this.savingAliasState = false;
        } else {
          this.status = statuses.ERROR;
          this.savingAliasState = false;
          logError("b2b-vpn-administration", "Error saving VPN alias");
        }
      }
    },
    toggleWideTable() {
      this.isWideTable = !this.isWideTable;
    },
  },
  computed: {
    columns() {
      return createTableColumn({
        column: this.selectedSorting,
        sortDirection: this.sorting[this.selectedSorting],
      });
    },
    rows() {
      let filteredRows = this.searchRows();

      if (this.selectedSorting) {
        filteredRows = this.sortRows(filteredRows);
      }

      return filteredRows;
    },
    rowsForPage() {
      return this.rows.slice((this.page - 1) * this.pageSize, this.page * this.pageSize);
    },
    tableRowsLimit() {
      return this.pageSize > this.rowsForPage.length ? this.rowsForPage.length : this.pageSize;
    },
    errorMessage() {
      return this.tableData && this.tableData.length === 0 ? this.t("connectedVpns.noData") : "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";

.telia-grid-wrapper telia-grid {
  transition: max-width 0.3s ease-in;
}
.telia-grid-wrapper.wide-table telia-grid {
  max-width: 100%;
}

telia-heading {
  margin-bottom: $telia-spacing-32;
}
</style>
