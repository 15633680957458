<template>
  <section :aria-label="t('connectedVpns.alias.heading', { 0: vpnId })" t-id="updateAlias">
    <telia-text-input
      :label="t('connectedVpns.alias.inputLabel')"
      :value="alias"
      @input="handleInputChange"
      type="text"
      required
      :required-error-message="t('connectedVpns.alias.requiredFieldMessage')"
    />

    <div class="action-buttons-wrapper">
      <telia-button
        t-id="cancelButton"
        class="cancel-button"
        :text="t('connectedVpns.alias.cancelButton')"
        variant="secondary"
        size="sm"
        type="button"
        :ally-label="t('connectedVpns.alias.accessibleCancelButton')"
        @click="closeModal()"
      />
      <telia-button
        t-id="saveButton"
        :text="t('connectedVpns.alias.saveButton')"
        variant="primary"
        :disabled="saving"
        size="sm"
        type="button"
        :ally-label="t('connectedVpns.alias.accessibleSaveButton')"
        @click="saveAlias()"
      />
      <div class="spinner-wrapper" v-if="saving">
        <b2x-spinner size="small" />
      </div>
    </div>

    <telia-notification v-if="status" :status="status.type" heading-tag="h3">
      <span slot="heading">
        <telia-visually-hidden>{{ t(status.content) }}</telia-visually-hidden>
        <span>{{ t(status.heading) }}</span></span
      >
      <telia-p slot="content">{{ t(status.content) }}</telia-p>
    </telia-notification>
  </section>
</template>

<script>
import { translateSetup, translateMixin } from "../locale";

export default {
  name: "UpdateAlias",
  mixins: [translateMixin],
  props: {
    alias: {
      type: String,
    },
    vpnId: {
      type: String,
    },
    status: {
      type: Object,
    },
    saving: {
      type: Boolean,
    },
  },
  async created() {
    await translateSetup();
  },
  methods: {
    closeModal() {
      this.$emit("close-modal");
    },
    saveAlias() {
      this.$emit("saveAlias", this.aliasValue);
    },
    handleInputChange(event) {
      this.aliasValue = event.target.value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";
.action-buttons-wrapper {
  margin: $telia-spacing-24 0;
  display: flex;
}

.cancel-button {
  margin-right: $telia-spacing-12;
}

.spinner-wrapper {
  margin-left: 10px;
  margin-top: 8px;
}
</style>
