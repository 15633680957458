<template>
  <div>
    <section data-test-id="b2b-vpn-administration" class="page-header">
      <telia-grid>
        <telia-row>
          <telia-col width="12">
            <telia-heading tag="h1" variant="title-300">{{
              t("connectedVpns.heading")
            }}</telia-heading>
          </telia-col>
        </telia-row>
      </telia-grid>
    </section>
    <section class="page-content">
      <VpnTable />
    </section>
  </div>
</template>

<script>
import VpnTable from "./components/vpn-table";
import { translateSetup, translateMixin } from "./locale";

export default {
  name: "App",
  mixins: [translateMixin],
  components: {
    VpnTable,
  },
  async created() {
    await translateSetup(["mybusiness"]);
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/colors/variables";

.page-header {
  background-color: $telia-gray-100;
  padding: $telia-spacing-64 0;
}

.page-content {
  padding: $telia-spacing-24 0 $telia-spacing-48;
}
</style>
